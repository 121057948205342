@import '@/styles/variables';

.select-wrapper {
  .selectSearch {
    &__control {
      border: none;
      flex-wrap: nowrap;
      cursor: pointer;
      transition: background-color $transition-time $transition-variant;
      color: $font-color-dark;
      box-shadow: unset;
      min-width: 100%;
      width: max-content;

      &--menu-is-open,
      &:hover {
        background-color: $color-gray-light;

        .selectSearch__dropdown-indicator {
          color: $color-black;
        }
      }
    }

    &__value-container {
      min-width: max-content;
    }

    &__single-value {
      align-items: center;
      display: flex;
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      min-width: 100%;
      width: max-content;
      z-index: $z-index-header-dropdown;
    }

    &__option {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      background-color: unset;
      transition: background-color $transition-time $transition-variant;

      &--is-focused {
        background-color: $color-gray;
      }

      &--is-selected,
      &--is-selected:hover {
        background-color: $color-green-main;
        color: $font-color-light;
        cursor: default;
      }
    }
  }
}


